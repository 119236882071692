<template>
  <div class="page-container-header">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>证件打印</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container-body">
      <div class="main-view">
        <!-- 用户列表 -->
        <div class="user-list">
          <el-checkbox-group v-model="checkedUser">
            <el-checkbox class="user-item" v-for="item in userList" :label="item" :key="item.m_id">
              <div class="user-info">
                <span>{{item.real_name ? item.real_name : '微信昵称：' + item.nick}}</span>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 预览打印区域 -->
        <div height="100%" style="width: 100%;display: flex;align-items: center;justify-content: center;" v-if="checkedUser.length > 0">
          <div class="preview" id="printMe" ref="preview" style="width: 315px">
            <div class="print-item" v-for="item in checkedUser" :key="item.m_id" style="width: 315px;height: 200px;">
              <div class="user-print-card" style="position: relative;">
                <div class="name" v-show="diyData.name_visible === 1" :style="'font-size: '+ diyData.name_size + 'px; transform: translate3d('+ diyData.name_x + 'px, ' + diyData.name_y + 'px, 0px); font-weight: ' + ((diyData.name_bold === 2) ? 'bold': '400') + ';'" v-if="item.real_name">{{item.real_name ? item.real_name : '微信昵称：' + item.nick }}</div>
                <div class="name" v-show="diyData.name_visible === 1" :style="'font-size: '+ diyData.name_size + 'px; transform: translate3d('+ diyData.name_x + 'px, ' + diyData.name_y + 'px, 0px); font-weight: ' + ((diyData.name_bold === 2) ? 'bold': '400') + ';'" v-else>微信昵称：{{item.nick }}</div>
                <div class="company" v-show="diyData.company_visible === 1" :style="'font-size: '+ diyData.company_size + 'px; transform: translate3d('+ diyData.company_x + 'px, ' + diyData.company_x + 'px, 0px); font-weight: ' + ((diyData.company_bold === 2) ? 'bold': '400') + ';'">{{item.company}}</div>
                <div class="offices" v-show="diyData.offices_visible === 1" :style="'font-size: '+ diyData.offices_size + 'px; transform: translate3d('+ diyData.offices_x + 'px, ' + diyData.offices_y + 'px, 0px); font-weight: ' + ((diyData.offices_bold === 2) ? 'bold': '400') + ';'">{{item.offices}}</div>
                <div class="duties" v-show="diyData.duties_visible === 1" data-name="duties" :style="'font-size: '+ diyData.duties_size + 'px; transform: translate3d('+ diyData.duties_x + 'px, ' + diyData.duties_y + 'px, 0px); font-weight: ' + ((diyData.duties_bold === 2) ? 'bold': '400') + ';'">{{item.duties}}</div>
                <div class="ewm"  v-show="diyData.ewm_visible === 1" :style="'width:' + diyData.ewm_size + 'px; height:' + diyData.ewm_size + 'px; transform: translate3d('+ diyData.ewm_x + 'px, ' + diyData.ewm_y + 'px, 0px);'">
                  <vue-qr :text="item.m_id" :size="diyData.ewm_size" :margin="0" level="H" tag="img"></vue-qr>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-data" v-else style="width: 100%;">
          <el-empty description="暂无待打印数据" />
        </div>
        <!-- 设置样式按钮 -->
        <div class="tool-bar">
          <el-button type="danger" size="mini" round class="tool-item" v-show="checkedUser.length > 0" v-print="printObj">生成打印页面</el-button>
          <el-button type="primary" size="mini" round class="tool-item" @click="setStyle">设置打印样式</el-button>
          <el-button type="success" size="mini" round class="tool-item" @click="exportExcel">导出Excel</el-button>
        </div>
      </div>
      <!-- 打印样式配置 -->
      <el-dialog :visible.sync="setVisble" title="打印样式配置" width="80%" :before-close="setClose" destroy-on-close>
        <template #header>
          <h4>配置证件样式</h4>
        </template>
        <template #default class="style-dialog">
          <div class="set-panel">
            <div class="card-preview">
              <div class="name drag-item" v-show="diyData.name_visible === 1" data-name="name" :style="'font-size: '+ diyData.name_size + 'px; transform: translate3d('+ diyData.name_x + 'px, ' + diyData.name_y + 'px, 0px); font-weight: ' + ((diyData.name_bold === 2) ? 'bold': '400') + ';'">测试姓名</div>
              <div class="company drag-item" v-show="diyData.company_visible === 1" data-name="company" :style="'font-size: '+ diyData.company_size + 'px; transform: translate3d('+ diyData.company_x + 'px, ' + diyData.company_x + 'px, 0px); font-weight: ' + ((diyData.company_bold === 2) ? 'bold': '400') + ';'">这里显示的是公司/单位名称</div>
              <div class="offices drag-item" v-show="diyData.offices_visible === 1" data-name="offices" :style="'font-size: '+ diyData.offices_size + 'px; transform: translate3d('+ diyData.offices_x + 'px, ' + diyData.offices_y + 'px, 0px); font-weight: ' + ((diyData.offices_bold === 2) ? 'bold': '400') + ';'">部门名称</div>
              <div class="duties drag-item" v-show="diyData.duties_visible === 1" data-name="duties" :style="'font-size: '+ diyData.duties_size + 'px; transform: translate3d('+ diyData.duties_x + 'px, ' + diyData.duties_y + 'px, 0px); font-weight: ' + ((diyData.duties_bold === 2) ? 'bold': '400') + ';'">职位名称</div>
              <div class="ewm drag-item" data-name="ewm" v-show="diyData.ewm_visible === 1" :style="'width:' + diyData.ewm_size + 'px; height:' + diyData.ewm_size + 'px; transform: translate3d('+ diyData.ewm_x + 'px, ' + diyData.ewm_y + 'px, 0px);'">
                <img src="../../../../../assets/img/shell/content/testEwm.png"/>
              </div>
            </div>
            <div class="form-area">
              <el-descriptions direction="vertical" class="margin-top" title="样式配置" :column="6" size="small" border>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    是否显示姓名
                  </div>
                  <el-radio-group v-model="diyData.name_visible">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    姓名文字大小
                  </div>
                  <el-input-number v-model="diyData.name_size" :min="0" :max="100" />
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    姓名文字是否加粗
                  </div>
                  <el-radio-group v-model="diyData.name_bold">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">加粗</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    是否显示公司
                  </div>
                  <el-radio-group v-model="diyData.company_visible">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    公司文字大小
                  </div>
                  <el-input-number v-model="diyData.company_size" :min="0" :max="100" />
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    公司文字是否加粗
                  </div>
                  <el-radio-group v-model="diyData.company_bold">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">加粗</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    是否显示部门
                  </div>
                  <el-radio-group v-model="diyData.offices_visible">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    部门文字大小
                  </div>
                  <el-input-number v-model="diyData.offices_size" :min="0" :max="100" />
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    部门文字是否加粗
                  </div>
                  <el-radio-group v-model="diyData.offices_bold">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">加粗</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    是否显示职位
                  </div>
                  <el-radio-group v-model="diyData.duties_visible">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    职位文字大小
                  </div>
                  <el-input-number v-model="diyData.duties_size" :min="0" :max="100" />
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    职位文字是否加粗
                  </div>
                  <el-radio-group v-model="diyData.duties_bold">
                    <el-radio :label="1">正常</el-radio>
                    <el-radio :label="2">加粗</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    是否显示底部二维码
                  </div>
                  <el-radio-group v-model="diyData.ewm_visible">
                    <el-radio :label="1">显示</el-radio>
                    <el-radio :label="2">隐藏</el-radio>
                  </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item :span="2">
                  <div class="cell-item-title">
                    二维码大小
                  </div>
                  <el-input-number v-model="diyData.ewm_size" :min="60" :max="250" />
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </template>
        <template #footer>
          <div style="flex: auto">
            <el-button size="default" type="primary" @click="submitEditForm">更新配置</el-button>
          </div>
        </template>
      </el-dialog>

      <!-- 生成打印页面 -->
      <el-dialog :visible.sync="printVisble" title="打印" width="80%" :before-close="setClose" destroy-on-close>
        <template #header>
          <h4>点击右键打印</h4>
        </template>
        <template #default class="">
          <div ref="printCanvas">
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
// 拖动插件
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
gsap.registerPlugin(Draggable)

export default {
  name: 'Certificate',
  comments: {
    VueQr
  },
  data () {
    return {
      pageData: {
        pageTitle: '',
        m_id: this.$route.params.mId
      },
      diyData: {
        tid: this.$route.params.mId,
        name_visible: 1,
        name_size: 14,
        name_bold: 2,
        name_x: 0,
        name_y: 0,

        company_visible: 1,
        company_size: 14,
        company_bold: 1,
        company_x: 0,
        company_y: 0,

        offices_visible: 1,
        offices_size: 14,
        offices_bold: 1,
        offices_x: 0,
        offices_y: 0,

        duties_visible: 1,
        duties_size: 14,
        duties_bold: 2,
        duties_x: 0,
        duties_y: 0,

        ewm_visible: 1,
        ewm_size: 60,
        ewm_x: 0,
        ewm_y: 0
      },
      // 所有用户列表
      userList: [],
      // 已选择的用户列表
      checkedUser: [],
      // 所有可拖动元素
      dragList: [],
      setVisble: false,
      // 生成打印图片
      printVisble: false,
      printCanvas: null,
      preview: null,
      printObj: {
        id: 'printMe',
        standard: 'loose',
        popTitle: 'good print',
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      }
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/shell-set-certificate', { params: { tid: this.$route.params.mId } })
      if (res.status === 200) {
        this.pageData.pageTitle = res.data.title

        this.userList = res.data.userList
        this.diyData.name_visible = Number(res.data.diyData.name_visible)
        this.diyData.name_size = Number(res.data.diyData.name_size)
        this.diyData.name_bold = Number(res.data.diyData.name_bold)
        this.diyData.name_x = Number(res.data.diyData.name_x)
        this.diyData.name_y = Number(res.data.diyData.name_y)

        this.diyData.company_visible = Number(res.data.diyData.company_visible)
        this.diyData.company_size = Number(res.data.diyData.company_size)
        this.diyData.company_bold = Number(res.data.diyData.company_bold)
        this.diyData.company_x = Number(res.data.diyData.company_x)
        this.diyData.company_y = Number(res.data.diyData.company_y)

        this.diyData.offices_visible = Number(res.data.diyData.offices_visible)
        this.diyData.offices_size = Number(res.data.diyData.offices_size)
        this.diyData.offices_bold = Number(res.data.diyData.offices_bold)
        this.diyData.offices_x = Number(res.data.diyData.offices_x)
        this.diyData.offices_y = Number(res.data.diyData.offices_y)

        this.diyData.duties_visible = Number(res.data.diyData.duties_visible)
        this.diyData.duties_size = Number(res.data.diyData.duties_size)
        this.diyData.duties_bold = Number(res.data.diyData.duties_bold)
        this.diyData.duties_x = Number(res.data.diyData.duties_x)
        this.diyData.duties_y = Number(res.data.diyData.duties_y)

        this.diyData.ewm_visible = Number(res.data.diyData.ewm_visible)
        this.diyData.ewm_size = Number(res.data.diyData.ewm_size)
        this.diyData.ewm_x = Number(res.data.diyData.ewm_x)
        this.diyData.ewm_y = Number(res.data.diyData.ewm_y)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 设置样式按钮
    setStyle () {
      this.setVisble = true
      const that = this
      that.$nextTick(() => {
        that.dragList = Draggable.create('.drag-item',
          {
            bounds: '.card-preview',
            inertia: true,
            onDragEnd: function (item) {
              if (item.target.dataset.name === 'name') {
                that.diyData.name_x = this.x
                that.diyData.name_y = this.y
              } else if (item.target.dataset.name === 'company') {
                that.diyData.company_x = this.x
                that.diyData.company_y = this.y
              } else if (item.target.dataset.name === 'offices') {
                that.diyData.offices_x = this.x
                that.diyData.offices_y = this.y
              } else if (item.target.dataset.name === 'duties') {
                that.diyData.duties_x = this.x
                that.diyData.duties_y = this.y
              } else {
                that.diyData.ewm_x = this.x
                that.diyData.ewm_y = this.y
              }
            }
          })
      })
    },
    // 提交Diy表单
    async submitEditForm () {
      const { data: res } = await this.$http.post('/shell-set-certificate', this.diyData)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.setVisble = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    setClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 导出打印用户数据
    exportExcel () {
      console.log(this.$http.defaults.baseURL)
      window.open(this.$http.defaults.baseURL + 'shell-export-excel?tid=' + this.$route.params.mId, '_blank')
      // window.open(href, "_blank");
      // const res = await this.$http.get('/shell-export-excel', { params: { tid: this.$route.params.mId } })
      // console.log('下载的文件', res)
      // const link = document.createElement('a')
      // try {
      //   const blob = res.data
      //   const _fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]// 文件名，中文无法解析的时候会显示 _(下划线),生产环境获取不到
      //   link.style.display = 'none'
      //   // 兼容不同浏览器的URL对象
      //   const url = window.URL || window.webkitURL || window.moxURL
      //   link.href = url.createObjectURL(blob)
      //   link.download = _fileName
      //   link.click()
      //   window.URL.revokeObjectURL(url)
      this.$message.success('正在导出中，请稍后')
      // } catch (e) {
      //   console.log('下载的文件出错', e)
      // }
    }
  }
}
</script>

<style scoped>
.page-container-header{
  background-color: #FFFFFF;
  padding: 20px;
}
.page-title{
  font-weight: 600;
  font-size: 20px;
  padding: 20px 0;
}
.page-desc{
  font-size: 14px;
  color: #909399;
}
.page-container-body{
  padding: 20px;
}
.main-view{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  position: relative;
}
.main-view .user-list{
  padding: 6px;
  text-align: left;
  width: max-content;
  background-color: #FFFFFF;
  height: 500px;
  overflow-y: auto;
}
.user-item{
  display: block;
}
.main-view .user-list .user-info{
  display: block;
  width: 100%;
}
.main-view .preview{
  width: 355px;
  padding: 20px;
  height: 500px;
  overflow-y: auto;
  background-color: #FFFFFF;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
}
.main-view .preview::-webkit-scrollbar{
  width:4px;
  height:16px;
  background-color:#F5F5F5;
}
/*定义滚动条轨道内阴影+圆角*/
.main-view .preview::-webkit-scrollbar-track{
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:4px;
  background-color:#F5F5F5;
}
/*定义滑块内阴影+圆角*/
.main-view .preview::-webkit-scrollbar-thumb{
  border-radius:4px;
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
  background-color:#555;
}

.main-view .preview .print-item{
  display: block;
  width: 315px;
  height: 200px;
  background-color: #FAFCFF;
  border-bottom: 1px dashed rgba(0,0,0,0.06);
}
.user-print-card{
  overflow: hidden;
}
.user-print-card .name{
  color: #000000;
  position: absolute;
  left: 0;
  top: 0;
}
.user-print-card .company{
  color: #000000;
  position: absolute;
  left: 0;
  top: 0;
}
.user-print-card .offices{
  color: #000000;
  position: absolute;
  left: 0;
  top: 0;
}
.user-print-card .duties{
  color: #000000;
  position: absolute;
  left: 0;
  top: 0;
}
.user-print-card .ewm{
  position: absolute;
  left: 0;
  top: 0;
}
.main-view .print-set{
  flex: 1;
  height: 100%;
  background-color: #FFFFFF;
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.set-panel{
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  background-color: #EBEDF0;
}
.card-preview{
  width: 315px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-size: cover;
  text-align: center;
  background-color: #FFFFFF;
  position: relative;
}
.set-panel  .form-area{
  margin-left: 40px;
  flex: 1;
}
.card-preview .name{
  font-weight: bold;
  color: #000000;
  position: absolute;
  top: 0;
  left: 0;
}
.card-preview .company{
  color: #000000;
  position: absolute;
  top: 0;
  left: 0;
}
.card-preview .offices{
  color: #000000;
  position: absolute;
  top: 0;
  left: 0;
}
.card-preview .duties{
  color: #000000;
  position: absolute;
  top: 0;
  left: 0;
}
.card-preview .ewm{
  width: 60px;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.card-preview .ewm img{
  width: 100%;
  height: 100%;
}
.card-preview .ewm canvas{
  width: 100%;
  height: 100%;
}
.tool-bar{
  position: absolute;
  top: 0;
  right: 20px;
}
.print-item{
  width: 280px;
  height: 450px;
  text-align: center;
}
.print-item .logo{
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}
.print-item .logo img{
  width: 100%;
  height: 100%;
}
.user-print-card{
  width: 100%;
  height: 100%;
  background-size: cover;
  display: inline-block;
  -webkit-print-color-adjust: exact;
  position: relative;
}
.print-item .title{
  font-weight: bold;
}
.print-item .more-info{
  padding: 20px;
}
.print-item .ewm{
  width: 60px;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
}
.print-item .ewm svg{
  width: 100%!important;
  height: 100%!important;
}
.print-start{
  position: absolute;
  bottom: 0;
  right: 10px;
}
.drag-item{
  user-select: none;
  /*touch-action: none;*/
  /*overflow: hidden;*/
}
</style>
